<div class="d-flex flex-row flex-wrap">
  <div *ngFor="let attachment of attachments">
    <span
      *ngIf="hasImageExtension(attachment.file.fileName)"
      [command]="chipClickedCmd"
      [commandValue]="attachment"
      [stopPropagation]="true"
    >
      <div class="img-container" [style.background-image]="backgroundImage(attachment.publicUrl)" loading="lazy"></div>
    </span>
    <span
      *ngIf="!hasImageExtension(attachment.file.fileName)"
      [command]="chipClickedCmd"
      [commandValue]="attachment"
      [stopPropagation]="true"
      class="chip gap-1"
    >
      <mat-icon fontSet="material-symbols-outlined" [svgIcon]="fileIconClass(attachment.file.fileName)"></mat-icon>
      <div title="{{ attachment.file.fileName }}" class="text-truncate">
        {{ attachment.file.fileName }}
      </div>
    </span>
  </div>
  <label *ngIf="attachments.length === 0">{{ noFilesMessage }}</label>
</div>
